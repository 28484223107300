

const Refund_Policy = () => {
    return (
        <>
            <div style={{justifyContent:'center',alignItems:'center',textAlign:"center",marginTop:50,padding:"30px 140px"}} >
                <h1 style={{marginBottom:"30px"}} ><u>Refund Policy</u></h1>
                <p  >If approved, you’ll be automatically refunded on your original payment method within 10
                    business days. If more than 15 business days have passed since we’ve approved your
                    return, please contact us.</p>
            </div>
        </>
    )
}

export default Refund_Policy