import PartnerBookings from "../PartnerProgreece";
import PartnerSidebar from "../PartnerSidebar";
import "../partner.css"

const PartnerHome = () => {
  return (
    <>
        <PartnerSidebar/>
        <PartnerBookings/>
    </>
  )
}

export default PartnerHome