import { useNavigate } from "react-router";
import { useState } from "react";
import ContactType from "./contactInterface"

const Contact = () => {

    const [user, setUser] = useState<ContactType>({
        name: "",
        email: "",
        phone: "",
        description: ""
    });

    const navigate = useNavigate();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const handledescription = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await fetch(
                `${process.env.React_App_DYO_CHEF_ContactUs_URL}`,
                {
                    method: "POST",
                    body: JSON.stringify(user),
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            const respData = await response.json();
            console.log("data", respData);
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>

            <div className="getway-wrapper">
                <div className="getway-inner">
                    <div className="getway-inner-items" >
                        <h2> Welcome to DYO <span> CHEF</span> </h2>
                        <div className="mt-5 mb-4">
                            <strong className="fs-5">Contact US</strong>
                        </div>
                        <form className="d-flex flex-column gap-4" onSubmit={handleSubmit}>

                            <div className="form-group">
                                <label className="form-label fw-medium"> Name </label>
                                <input className="form-control form-input" type="text" placeholder=" Enter your full name" name="name" value={user.name} onChange={handleChange} />
                            </div>

                            <div className="form-group">
                                <label className="form-label fw-medium"> Email </label>
                                <input className="form-control form-input" type="text" placeholder=" Enter your full name" name="email" value={user.email} onChange={handleChange} />
                            </div>

                            <div className="form-group">
                                <label className="form-label fw-medium"> Phone </label>
                                <input className="form-control form-input" type="text" placeholder=" Enter your full name" name="phone" value={user.phone} onChange={handleChange} />
                            </div>

                            <div className="form-group">
                                <textarea className="form-control form-input" id="exampleFormControlTextarea1" placeholder="Please Write your massage in details" name="description" value={user.description} onChange={handledescription}></textarea>
                            </div>

                            <button className="btn btn-rounded btn-dyo-primary "> Submit </button>

                        </form>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Contact;