import { Link } from "react-router-dom";

const PartnerProgreece = () => {
  return (
    <>
      <div className="dashboard-scroller">
        <div className="seprator-50">
          <h4 className="fw-bold mx-2">Chef Bookings</h4>
          <div className="row card-container">
            <div className="col">
              <Link to="/partner/bookings"><div className="booking-card">
                <div className="d-flex">
                  <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/total-booking.svg" alt="" />
                  <div className="dashboard-navigation">
                    <p>Total Bookings</p>
                    <h6 className="fw-medium">85</h6>
                  </div>
                </div>
              </div>
              </Link>
            </div>
            <div className="col">
              <Link to="/partner/bookings">
              <div className="booking-card">
                <div className="d-flex">
                  <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%2011.svg" alt="" />
                  <div className="dashboard-navigation">
                    <p>Upcoming Booking</p>
                    <h6 className="fw-medium">85</h6>
                  </div>
                </div>
              </div>
              </Link>
            </div>
            <div className="col">
              <Link to="/partner/bookings">
              <div className="booking-card">
                <div className="d-flex">
                  <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%208.svg" alt="" />
                  <div className="dashboard-navigation">
                    <p>Past Bookings</p>
                    <h6 className="fw-medium">85</h6>
                  </div>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="seprator-50">
          <h4 className="fw-bold mx-2">Chef Performance Indicators</h4>
          <div className="row card-container">
            <div className="col">
              <div className="parformance-card">
                <div className="d-flex">
                  <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fruppes.svg" alt="" />
                  <div className="dashboard-navigation">
                    <p>Total Earnings</p>
                    <h6 className="fw-medium">85</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="parformance-card">
                <div className="d-flex">
                  <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fruppes.svg" alt="" />
                  <div className="dashboard-navigation">
                    <p>This Month</p>
                    <h6 className="fw-medium">85</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="parformance-card">
                <div className="d-flex">
                  <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fruppes.svg" alt="" />
                  <div className="dashboard-navigation">
                    <p>Last Month</p>
                    <h6 className="fw-medium">85</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="parformance-card">
                <div className="d-flex">
                  <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fruppes.svg" alt="" />
                  <div className="dashboard-navigation">
                    <p>Due Amount</p>
                    <h6 className="fw-medium">85</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerProgreece;
