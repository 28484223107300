// import React from 'react'
import { useEffect, useState } from "react";
import "./profile.css";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/index"

interface userdetail {
  firstName: string;
  lastName: string;
  phone: string;
  address: string;
  email: string;
}

const Userprofile = () => {
  const [user, setUser] = useState<userdetail>();
  const [address, setAddress] = useState([]);

  const token = localStorage.getItem("accessToken");
  const userData: any = localStorage.getItem("user");
  const userObject = JSON.parse(userData);
  const userId = userObject.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.React_App_DYO_CHEF_Save_Address_URL}${userId}/addresses`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const addresses = data.data.addresses;
        setAddress(addresses);
        // setAddressid(addresses.toString());
        console.log("Addresses fetched: ", addresses);
      } catch (error) {
        console.error("Fetch error: ", error);
      }
    };

    fetchData();
  }, [token, userId]);

  const handleAddressSelect = (e: any) => {
    console.log("Save Address", e.target.value);
    const selectedValue = JSON.parse(e.target.value)
    console.log(selectedValue.id)
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.React_App_DYO_CHEF_UserInfo_URL}${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        console.log("________+_+_+_+_", data.data.user);
        const userdetails = data.data.user;
        setUser(userdetails);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchData();
  }, [token, userId]);


  if (!user) {
    return <div className="text-center loader-position"><Loader /></div>;
  }

  return (
    <>
      <div className="container">
        <div className="d-flex profile-gatway">
          <Link to='/bookingview' className="back-button">
            <i className="fa fa-angle-left ms-3"></i>
          </Link>
          <h2 className="spacing-head">
            Profile
          </h2>
        </div>
        <div className="row">
          <div className="col-sm profile-image-items">
            <img
              src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/dyo-profile.png"
              className="dyo-profile rounded-circle"
              alt="user profile"
            />
            <button className="profile-edit">
              <Link to="/user/profile/update"><i className="fa fa-edit"></i></Link>
            </button>
          </div>

          <div className="col-sm rounded">
            <form className="profile-inner-items">
              <div className="mb-3 d-flex">
                <label htmlFor="inputPassword" className="col-sm-2 col-form-label profile-label">Name</label>
                <div className="col-sm-10">
                  <h6 className="profile-fields form-control">
                    {user.firstName} {user.lastName}
                  </h6>
                </div>
              </div>
              <div className="mb-3 d-flex">
                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Email</label>
                <div className="col-sm-10">
                  <h6 className="profile-fields form-control">
                    {user.email}
                  </h6>
                </div>
              </div>
              <div className="mb-3 d-flex">
                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Phone</label>
                <div className="col-sm-10">
                  <h6 className="profile-fields form-control">
                    {user.phone}
                  </h6>
                </div>
              </div>

              <div className="mb-3 d-flex">
                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Address</label>
                <div className="col-sm-10">
                  <div className="form-group mb-4 ">
                    {address.map((address: any, index: any) => (
                      <div className="d-flex profile-address mb-1 profile-fields" key={index}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="address"
                          value={JSON.stringify(address)}
                          id={`flexCheckDefault-${index}`}
                          onClick={handleAddressSelect}
                        />
                        <div className="mx-2">
                          <h6>{address.firstName} {address.lastName}</h6>
                          <p>{address.address},{address.city},{address.region},{address.zip}</p>
                          <p>{address.phone}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </form>
          </div>


        </div>
      </div>
    </>
  );
};

export default Userprofile;
