
import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";


interface TestimonialProps {
  testimonial: {
    imageUrl: string;
    description: string;
    name: string;
    rating: string;
  }[];
}

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
        dots:true
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        Infinity:true,
        dots:true
      }
    }
  ],

  appendDots: (dots: string | any[]) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ul> {dots.slice(0, 3)} </ul> {/* Display only the first three dots */}
    </div>
  ),
  customPaging: () => (
    <button>•</button> // Use a custom dot
  ),


  afterChange: () => {
    // Reset all dots to default style
    document.querySelectorAll('.slick-dots li button:before').forEach(dot => {
      (dot as HTMLElement).style.color = 'pink';
      (dot as HTMLElement).style.opacity = '0.5';
    });
    // Style the active dot
    const activeDot = document.querySelector('.slick-dots li.slick-active button:before');
    if (activeDot) {
      (activeDot as HTMLElement).style.color = 'blue';
      (activeDot as HTMLElement).style.opacity = '0.75';
    }
  }
};

const Testimonial: React.FC<TestimonialProps> = ({ testimonial }) => {
  
  useEffect(() => {
    // Initial styling for active dot
    const activeDot = document.querySelector('.slick-dots li.slick-active button:before');
    if (activeDot) {
      (activeDot as HTMLElement).style.color = 'red';
      (activeDot as HTMLElement).style.opacity = '0.75';
    }
  }, []);
  
  return (
    <>
      <div className="seprator-100 testimonial-section">
        <div className="add-section mt-5">
          <h2 className="text-center pt-5">Testimonial</h2>
          <p className="text-center">Kinds Words From Clients</p>
        </div>
        <div className="testimonial-card">
          <div className="container mt-5">
            <Slider {...settings}>
              {testimonial.map((e, index) => (
                <div className="col-md-4 g-4  mb-5" key={index}>
                  <div className="card mb-3 p-2 testimonial-layout" key={index}>
                    <div className="row g-1">
                      <div className="card-body">
                        <p className="card-text testimonial-containe">{e.description}</p>
                      </div>
                      <div className="col-md-3">
                        <img src={e.imageUrl} className="card-img-top testimonial-image ms-4" alt={e.name} />
                      </div>
                      <div className="col-md-9 text-left">
                        <h5>{e.name}</h5>
                        <div className="text-warning">
                          {[...Array(parseInt(e.rating))].map((i) => (
                            <i className="fa fa-star" key={i}></i>
                          ))}
                          {[...Array(5 - parseInt(e.rating))].map((i) => (
                            <i className="fa fa-star-o" key={i}></i>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

    </>
  );
};

export default Testimonial;
