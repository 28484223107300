import Carousel from "../Carousel/index";
import Card from "../CardService";
import AllService from "../AllService";
import Advertisement from "../Advertisement";
import Chefcard from "../Chef-card";
import Footer from "../../components/ErrorBoundary/Footer";
import { useNavigate } from "react-router-dom";
import Testimonial from "../Testimonial";
import { useEffect, useState } from "react";
import { selectMeal } from "../../store/meals/meals-slice";
import { useAppDispatch, useAppSelector } from "../../store/appStore";
import Loader from "../../components/Loader/index";


const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [card, setCard] = useState([]);
  const [chefcard, setChefcard] = useState([]);
  const [ourstory, setOurstory] = useState({
    imageUrl: "",
    description: "",
    lable: "",
  });
  const [testimonial, setTestimonial] = useState([]);

  const [latitude, setLatitude] = useState<number | null>()
  const [longitude, setLongitude] = useState<number | null>()
  const [error, setError] = useState<string | null>()

  ///location code///
  useEffect(() => {
    const success = (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      setLatitude(latitude);
      setLongitude(longitude);

      localStorage.setItem('latitude', latitude.toString());
      localStorage.setItem('longitude', longitude.toString());
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    };
    console.log(latitude)
    console.log(longitude)


    const errorHandler = (error: GeolocationPositionError) => {
      setError(error.message);
      console.error(`Error getting geolocation: ${error.message}`);
    };

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(success, errorHandler);
    } else {
      setError("Geolocation is not supported by this browser.");
      console.log(error)
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await fetch(
        `${process.env.React_App_DYO_CHEF_HOME_URL}`
      );
      const json = await data.json();
      console.log(json)
      setIsLoading(false);
      setCard(json.data.primeSegments);
      setChefcard(json.data.ourTeam);
      setOurstory(json.data.ourStory);
      setTestimonial(json.data.testimonial);
    } catch (error) {
      console.log("API Condition check", error)
    }

  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(
    (state) => state.authorizer.isauthenticate
  );



  const handleBooking = (meal: string) => {
    dispatch(selectMeal(meal));

    if (isAuthenticated) {
      navigate("/booking");
    } else {
      localStorage.getItem("accessToken")
        ? navigate("/booking")
        : navigate("/login");
    }
  };


  return (
    <>
      <div className="text-center">
        {isLoading && <Loader />}
      </div>

      {<Carousel />}
      {
        <div
          onClick={() => {
            handleBooking("meal");
          }}
        >
          <Card card={card} />
        </div>
      }
      {<Advertisement advertisement={ourstory} />}
      {
        <div>
          <AllService />
        </div>
      }
      {<Chefcard chefcard={chefcard} />}
      {<Testimonial testimonial={testimonial} />}
      {<Footer />}
    </>
  );
};

export default Home;
