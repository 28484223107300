import { Link,useNavigate } from 'react-router-dom';
// import { RootState } from '../../store/appStore';
// import { useDispatch,useSelector } from 'react-redux';
import { selectMeal } from '../../store/meals/meals-slice';
import {useAppDispatch, useAppSelector } from '../../store/appStore';


const Meals = () => {

  const dispatch =useAppDispatch()
  // const selectedMeal = useAppSelector((state) => state.meals.selectedMeal)
  const isAuthenticated = useAppSelector((state) => state.authorizer.isauthenticate);

  const navigate = useNavigate();

   const handleMealSelection = (meal: string) => {
    dispatch(selectMeal(meal));
  };

  // Function to handle booking button click
  const handleBooking = () => {
    if (isAuthenticated) {
      navigate('/booking'); // Navigate to booking page if authenticated
    } else {
      localStorage.getItem('accessToken') ? navigate('/booking') : navigate('/login');
    }
    // localStorage.getItem('accessToken') ? navigate('/booking') : navigate('/login'); // Navigate to booking or login page based on access token availability
  };

  
  return (
    <>
        <div className="booking-screen" >
      <h1 className="text-center pt-3">Select Meals</h1>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4 ">
            <div className="Snacks-card border border-1 mb-5 shadow-sm p-4 bg-body ">
              <div className="card-head">
                <h2 className="booking-card-head mx-4 text-center">Breakfast / Snacks</h2>
              </div>
              <div className="card-content">
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem",color: "rgb(0, 128, 55);"}}></span> Meal for 4 people</h5>
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize:"1.4rem",color: "rgb(0, 128, 55);"}}></span> Trained & Verified Cooks</h5>
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color:" rgb(0, 128, 55);"}}></span> Healthy & Hygienic Food</h5>
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color:" rgb(0, 128, 55);"}}></span> Quick Service</h5>
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color:" rgb(0, 128, 55);"}}></span> Roti/Puri</h5>
                <p className="mx-4 mt-4 fw-lighter">Starting from</p>
                {/* <p>{selectedMeal}</p> */}
                <h2 className="mx-4 mt-1 fw-bold "><i className="fa fa-rupee"></i>499 /-</h2>
              </div>
              <div className="text-center mt-4">
              <button className="booking-button w-100 p-2 rounded-pill"  onClick={() => {
                      handleMealSelection('Breakfast'); // Dispatching action for selecting breakfast
                      handleBooking(); // Handling booking after meal selection
                    }}  ><Link to="=" style={{textDecoration:"none",color:"#000"}} >
                  Book Now
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="Snacks-card border border-1 shadow-sm p-4 bg-body">
              <div className="card-head">
                <h2 className="booking-card-head text-center">Lunch</h2>
              </div>
              <div className="card-content">
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color: "rgb(0, 128, 55);"}}></span> Meal for 4 people</h5>
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color: "rgb(0, 128, 55);"}}></span> Trained & Verified Cooks</h5>
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color: "rgb(0, 128, 55);"}}></span> Healthy & Hygienic Food</h5>
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color: "rgb(0, 128, 55);"}}></span> Quick Service</h5>
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color: "rgb(0, 128, 55);"}}></span> Roti/Puri</h5>
                <p className="mx-4 mt-4 fw-lighter">Starting from</p>
                <h2 className="mx-4 mt-1 fw-bold "><i className="fa fa-rupee"></i>499 /-</h2>
              </div>
              <div className="text-center mt-4">
              <button className="booking-button w-100 p-2 rounded-pill"  onClick={() => {
                      handleMealSelection('Breakfast'); // Dispatching action for selecting breakfast
                      handleBooking(); // Handling booking after meal selection
                    }}  ><Link to="=" style={{textDecoration:"none",color:"#000"}} >
                  Book Now
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="Snacks-card border border-1 shadow-sm p-4 bg-body">
              <div className="card-head">
                <h2 className="booking-card-head text-center">Dinner</h2>
              </div>
              <div className="card-content">
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color: "rgb(0, 128, 55);"}}></span> Meal for 4 people</h5>
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color: "rgb(0, 128, 55);"}}></span> Trained & Verified Cooks</h5>
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color: "rgb(0, 128, 55);"}}></span> Healthy & Hygienic Food</h5>
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color: "rgb(0, 128, 55);"}}></span> Quick Service</h5>
                <h5 className="mx-4 mt-4"><span id="boot-icon" className="bi bi-check2-circle" style={{fontSize: "1.4rem", color: "rgb(0, 128, 55);"}}></span> Roti/Puri</h5>
                <p className="mx-4 mt-4 fw-lighter">Starting from</p>
                <h2 className="mx-4 mt-1 fw-bold "><i className="fa fa-rupee"></i>499 /-</h2>
              </div>
              <div className="text-center mt-4">
              <button className="booking-button w-100 p-2 rounded-pill"  onClick={() => {
                      handleMealSelection('Breakfast'); // Dispatching action for selecting breakfast
                      handleBooking(); // Handling booking after meal selection
                    }}  ><Link to="=" style={{textDecoration:"none",color:"#000"}} >
                  Book Now
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Meals;