import Loaders from '../../images/Loader.gif'
import "./loader.css"

const Loader = () => {
  return (
    <div className="chef-loader-container">
        <img src={Loaders} alt='loder' />
    </div>
  );
};

export default Loader;
