import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Popover from "../../../components/PopoverButton";
import "./booking.css";
import { Link } from "react-router-dom";
import moment from "moment";
import {toast} from "react-toastify"

const timeOptions = [
  "8.00 am",
  "9.00 am",
  "10.00 am",
  "11.00 am",
  "12.00 pm",
  "1.00 pm",
  "2.00 pm",
  "3.00 pm",
  "4.00 pm",
  "5.00 pm",
  "6.00 pm",
  "7.00 pm",
  "8.00 pm",
  "9.00 pm",
  "10.00 pm",
];

function CreateBookings() {
  const [selectedRequirement, setSelectedRequirement] = useState<string>("");
  const [selectedMeals, setSelectedMeals] = useState<string[]>([]);
  const [selectedAdditionals, setSelectedAdditionals] = useState<string[]>([]);

  const [booking, setBooking] = useState({
    startTime: "",
    startDate: "",
    headCount: 4,
    endDate: "",
    endTime: "",
  });

  const [fare, setFare] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      const formData = {
        requirement: selectedRequirement,
        meals: selectedMeals,
        additionalServices: selectedAdditionals,
        ...booking,
      };
      console.log(formData);

      try {
        const response = await fetch(
          `${process.env.React_App_DYO_CHEF_Fare_Calculation_URL}`,
          {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
              "Contact-Type": "application/json",
            },
          }
        );
        let data = await response.json();
        setFare(data?.data?.finalPrice);
      } catch (error) {

        console.log("Api not response", error);
      }
    };
    fetchData();
  }, [selectedRequirement, selectedMeals, selectedAdditionals, booking, fare]);

  const navigate = useNavigate();

  const handleRequirementClick = (value: string) => {
    setSelectedRequirement(value);
  };

  const handleMealClick = (value: string) => {
    if (selectedMeals.includes(value)) {
      setSelectedMeals(selectedMeals.filter((item) => item !== value));
    } else {
      setSelectedMeals([...selectedMeals, value]);
    }
  };

  const handleAdditionalClick = (value: string) => {
    if (selectedAdditionals.includes(value)) {
      setSelectedAdditionals(
        selectedAdditionals.filter((item) => item !== value)
      );
    } else {
      setSelectedAdditionals([...selectedAdditionals, value]);
    }
  };

  const [validationError, setValidationError] = useState("");

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setBooking((prevBooking) => {
      const updatedBooking = {
        ...prevBooking,
        [name]: value,
      };

      if (name === "startDate" && prevBooking.endDate < value) {
        updatedBooking.endDate = value;
      }

      if (name === "startTime") {
        // Automatically set the end time based on the start time
        const endTimeIndex =
          timeOptions.findIndex((time) => time === value) + 2;
        updatedBooking.endTime = timeOptions[endTimeIndex] || "4";
      }

      validateTimes(
        updatedBooking.startDate,
        updatedBooking.endDate,
        updatedBooking.startTime,
        updatedBooking.endTime
      );
      return updatedBooking;
    });
  };

  const convertTo24HourFormat = (time: any) => {
    return moment(time, ["h:mm A"]).format("HH:mm:ss");
  };

  const validateTimes = (
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any
  ) => {
    if (startDate && endDate && startTime && endTime) {
      const start = convertTo24HourFormat(startTime);
      const end = convertTo24HourFormat(endTime);
      const startDateTime = moment(
        startDate + " " + start,
        "YYYY-MM-DD HH:mm:ss"
      );
      const endDateTime = moment(endDate + " " + end, "YYYY-MM-DD HH:mm:ss");

      if (start !== "00:00:00" && end !== "00:00:00") {
        if (startDateTime.isSameOrAfter(endDateTime)) {
          setValidationError(
            "Start date and time must be before end date and time."
          );
          return false;
        }
      }
    }
    setValidationError("");
    return true;
  };

  const handleDecrement = () => {
    if (booking.headCount > 4) {
      setBooking({ ...booking, headCount: booking.headCount - 1 });
    }
  };

  const handleIncrement = () => {
    if (booking.headCount < 25) {
      setBooking({ ...booking, headCount: booking.headCount + 1 });
    }
  };

  const handleSubmit = async () => {
    
     if (!selectedRequirement) {
      toast("Please select requirement");
      return;
    } else if (selectedMeals.length === 0) {
      toast("Please select meals");
      return;
    }
    else if (!booking.startTime) {
      toast("Please select time");
      return;
    } else if (!booking.startDate) {
      toast("Please select date");
      return;}

    const formData = {
      requirement: selectedRequirement,
      meals: selectedMeals,
      additionalServices: selectedAdditionals || [],
      ...booking,
    };

    console.log("234567890-rtyuiop", JSON.stringify(formData));

    navigate("/journalbookingdetails", {
      state: { bookingData: formData, userData: fare, fare },
    });
  };

  return (
    <>
      <div className="booking-getway-wrapper">
        <div className="booking-getway-inner">
          <div className="booking-getway-inner-items">
            <div className="d-flex ">
              <Link to="/" className="back-button">
                <i className="fa fa-angle-left me-2"></i>
              </Link>
              <h2 className="spacing-head">
                Book Your <span> CHEF</span>
              </h2>
            </div>

            <div className="form-group mb-2 seprator-52">
              <label className="form-label booking-field">
                Select Requirement
              </label>
              <div>
                <button
                  value="birthday"
                  onClick={() => handleRequirementClick("birthday")}
                  className={
                    selectedRequirement === "birthday"
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="requirement"
                >
                  Birthday
                </button>
                <button
                  value="kitty"
                  onClick={() => handleRequirementClick("kitty")}
                  className={
                    selectedRequirement === "kitty"
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="requirement"
                >
                  Kitty
                </button>
                <button
                  value="occasion"
                  onClick={() => handleRequirementClick("occasion")}
                  className={
                    selectedRequirement === "occasion"
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="requirement"
                >
                  Occasion
                </button>
                <button
                  value="Gathering"
                  onClick={() => handleRequirementClick("gathering")}
                  className={
                    selectedRequirement === "gathering"
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="requirement"
                >
                  Gathering
                </button>
                <button
                  value="other"
                  onClick={() => handleRequirementClick("other")}
                  className={
                    selectedRequirement === "other"
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="requirement"
                >
                  Other
                </button>
              </div>
            </div>
            <div className="form-group mb-2">
              <label className="form-label booking-field">Select Meal</label>
              <div>
                <button
                  value="breakfast"
                  onClick={() => handleMealClick("breakfast")}
                  className={
                    selectedMeals.includes("breakfast")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="meals"
                >
                  breakfast
                </button>
                <button
                  value="lunch"
                  onClick={() => handleMealClick("lunch")}
                  className={
                    selectedMeals.includes("lunch")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="meals"
                >
                  Lunch
                </button>
                <button
                  value="snacks"
                  onClick={() => handleMealClick("snacks")}
                  className={
                    selectedMeals.includes("snacks")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="meals"
                >
                  Snacks
                </button>
                <button
                  value="dinner"
                  onClick={() => handleMealClick("dinner")}
                  className={
                    selectedMeals.includes("dinner")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="meals"
                >
                  Dinner
                </button>
              </div>
            </div>

            <div className="form-group mb-2">
              <label className="form-label fw-medium m-0">No of people</label>
              <p className="booking-note">Contact to select more than 20</p>
              <div className="head-count-wrapper">
                <button className="minus" onClick={handleDecrement}>
                  -
                </button>
                <span className="num">{booking.headCount}</span>
                <button className="plus" onClick={handleIncrement}>
                  +
                </button>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <label className="form-label">Start Date</label>
                <div className="d-flex">
                  <input
                    className="form-control date-merge booking-form-date-input"
                    type="date"
                    id="date"
                    name="startDate"
                    value={booking.startDate}
                    onChange={handleChange}
                    min={new Date().toISOString().split("T")[0]}
                    required
                  />
                  <div className="mx-1">
                    <select
                      id="startTime"
                      className="booking-form-time-input"
                      name="startTime"
                      value={booking.startTime}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select Start Time
                      </option>
                      {timeOptions.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label">End Date</label>
                <div className="d-flex">
                  <input
                    className="form-control date-merge booking-form-date-input"
                    type="date"
                    id="date"
                    name="endDate"
                    value={booking.endDate}
                    onChange={handleChange}
                    min={
                      booking.startDate
                        ? booking.startDate
                        : new Date().toISOString().split("T")[0]
                    }
                    required
                  />
                  <div className="mx-1">
                    <select
                      id="endTime"
                      className="booking-form-time-input"
                      name="endTime"
                      value={booking.endTime}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select End Time
                      </option>
                      {timeOptions.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {validationError && (
                <div className="col-12 mt-2">
                  <div className="toast toast-danger">{validationError}</div>
                </div>
              )}
            </div>

            <div className="form-group mt-2">
              <label className="form-label booking-field mt-1">
                Additional Requirements
              </label>
              <div>
                <button
                  value="cleaner"
                  onClick={() => handleAdditionalClick("cleaner")}
                  className={
                    selectedAdditionals.includes("cleaner")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="additionalServices"
                >
                  Cleaner
                </button>
                <button
                  value="utensiles"
                  onClick={() => handleAdditionalClick("utensiles")}
                  className={
                    selectedAdditionals.includes("utensiles")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="additionalServices"
                >
                  Utensils
                </button>
                <button
                  value="cutlery"
                  onClick={() => handleAdditionalClick("cutlery")}
                  className={
                    selectedAdditionals.includes("cutlery")
                      ? "btn-selected"
                      : "btn-select"
                  }
                  name="additionalServices"
                >
                  Cutlery
                </button>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center booking-bottom-section ">
            <h3 className="mt-2 d-flex align-items-center">
              <i className="fa fa-rupee"></i>
              {fare}
              <Popover selectedMeals={selectedMeals} placement="bottom" />

            </h3>
            <button className="booking-button" onClick={handleSubmit}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateBookings;
