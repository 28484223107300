import { useNavigate } from "react-router";
import PersonalDetailsType from "./PersonalDetailsInterface";
import { useState } from "react";
import { Link } from "react-router-dom";

const PartnerRegistration = () => {
  const [user, setUser] = useState<PersonalDetailsType>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
  });

  const navigate = useNavigate();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.React_App_DYO_CHEF_Partner_Register_URL}`,
        {
          method: "POST",
          body: JSON.stringify(user),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const respData = await response.json();
        console.log("data", respData);
        navigate("/partner/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="getway-wrapper">
        <div className="getway-inner">
          <div className="getway-inner-items">
            <h2>
              Welcome to DYO <span> CHEF</span>
            </h2>
            <div className="d-flex justify-content-between align-items-center mt-5 mb-4">
              <strong className="fs-5">Partner Join</strong>
              <p className="mb-0 already-a-member">
                {" "}
                Already a partner? <Link to="/partner/login">Sign in</Link>{" "}
              </p>
            </div>
            <form className="d-flex flex-column gap-4" onSubmit={handleSubmit}>
              <div className="row -mx-1">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label fw-medium"> First Name </label>
                    <input
                      className="form-control form-input"
                      type="text"
                      placeholder=" Enter your first name"
                      name="firstName"
                      value={user.firstName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label fw-medium"> Last Name </label>
                    <input
                      className="form-control form-input"
                      type="text"
                      placeholder=" Enter your last name"
                      name="lastName"
                      value={user.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-medium"> Email </label>
                <input
                  className="form-control form-input"
                  type="email"
                  placeholder=" Enter your full name"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                />
              </div>
              <div className="row -mx-1">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label fw-medium"> Password </label>
                    <input
                      className="form-control form-input"
                      type="Password"
                      placeholder=" Enter your full name"
                      name="password"
                      value={user.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label fw-medium">
                      {" "}
                      Confirm Password{" "}
                    </label>
                    <input
                      className="form-control form-input"
                      type="Password"
                      placeholder=" Enter your full name"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-medium"> Phone </label>
                <input
                  className="form-control form-input"
                  type="number"
                  placeholder=" Enter your Number"
                  name="phone"
                  value={user.phone}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group already-a-member">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  I agree to the Terms and Conditions
                </label>
              </div>
              <button className="btn btn-rounded btn-dyo-primary">
                Sign up
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerRegistration;
