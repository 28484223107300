import { useNavigate } from "react-router";
import RegistrationType from "./registrationInterface";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {toast} from 'react-toastify'
const Registration: React.FC = () => {

  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [user, setUser] = useState<RegistrationType>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    role: "user"
  });

  const navigate = useNavigate();

  useEffect(() => {
    
    const longitude = localStorage.getItem("longitude");
    const latitude = localStorage.getItem('latitude')
    console.log("longitude",longitude)
    console.log("latitude",latitude)

  }, [])
  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isTermsAgreed) {
      toast("Please agree to the Terms and Conditions");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.React_App_DYO_CHEF_Registration_URL}`,
        {
          method: "POST",
          body: JSON.stringify(user),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const respData = await response.json();
        toast("Sign up successful!")
        console.log("data", respData);
        navigate('/login');
      } else {
        toast("User already exists.")
      }
    } catch (error) {
      toast("Something went wrong!")
      console.log(error)
    }
  }

  return (
    <>

      <div className="getway-wrapper">
        <div className="getway-inner">
          <div className="getway-inner-items" >
            <h2> Welcome to DYO <span> CHEF</span> </h2>
            <div className="d-flex justify-content-between align-items-center mt-5 mb-4">
              <strong className="fs-5"> Create Account</strong>
              <p className="mb-0 already-a-member"> Already a member? <Link to="/login">Sign in</Link> </p>
            </div>
            <form className="d-flex flex-column gap-4" onSubmit={handleSubmit}>
              <div className="row -mx-1">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label fw-medium">First Name </label>
                    <input className="form-control form-input" type="text" placeholder=" Enter your first name" name="firstName" value={user.firstName} onChange={handleChange} required />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label fw-medium"> Last Name </label>
                    <input className="form-control form-input" type="text" placeholder=" Enter your last name" name="lastName" value={user.lastName} onChange={handleChange} required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-medium"> Email </label>
                <input className="form-control form-input" type="email" placeholder=" Enter your email" name="email" value={user.email} onChange={handleChange} required />
              </div>
              <div className="row -mx-1">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label fw-medium"> Password </label>
                    <input className="form-control form-input" type="Password" placeholder=" Enter your password" name="password" value={user.password} onChange={handleChange} required />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label fw-medium"> Confirm Password </label>
                    <input className="form-control form-input" type="Password" placeholder=" Confirm password" />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label fw-medium"> Phone Number </label>
                <input className="form-control form-input" type="text" placeholder=" Enter your Phone Number" name="phone" value={user.phone} onChange={handleChange} required />
              </div>

              <div className="form-group already-a-member">
                <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={isTermsAgreed}
                  onChange={() => setIsTermsAgreed(!isTermsAgreed)} />
                <label className="form-check-label" htmlFor="exampleCheck1" >I agree to the Terms and Conditions</label>
              </div>
              <button className="btn btn-rounded btn-dyo-primary "> Sign up</button>

            </form>
          </div>
          <div className="or text-center">
          </div>
          <div className="text-center">
            <button className="social-link"><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fgoogle.svg" alt="" /></button>
            <button className="social-link"><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Ffacebook.svg" alt="" /></button>
          </div>

        </div>
      </div>
    </>
  )
}

export default Registration