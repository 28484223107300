import { Link } from "react-router-dom";
import "./payment-gatway.css";
import { useLocation, useNavigate } from "react-router-dom";

export const Payment_gatway = () => {
  const location = useLocation();

  console.log("-============", location.state.bookingData.booking.finalFare);
  const fare: any = location.state.bookingData.booking.finalFare;

  const calculateAmountToPay = (amount: number): number => {
    return amount * 0.25;
  };

  const amountToPay = calculateAmountToPay(fare);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/payment");
  };

  return (
    <>
      <div>
        <Link
          to="/journalbookingdetails"
          className="back-button d-flex ms-3 mt-3 "
        >
          <i className="fa fa-angle-left me-3"></i>
          <h5 className="m-0">Payment</h5>
        </Link>
        <div className="payment-gatway">
          <div className="payment-iteams">
            <h6>Pay Now</h6>
            <p>Please pay 25% of the total amount to conform your booking </p>
            <div className="payment-amount">
              <i className="fa fa-rupee"></i>
              <h2>{amountToPay}</h2>
            </div>
            <button className="pay-button" onClick={handleClick}>
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
