// import React from 'react'

import { Link } from "react-router-dom"

const PartnerSidebar = () => {
  return (
    <>
        <div className="w3-sidebar justify-content-center">
      <Link to="/partner/dashboard" className="w3-bar-item w3-button mx-3"
        ><i className='fa fa-home'></i>
        <p>Home</p></Link>
      <Link to="/partner/feedback" className="w3-bar-item w3-button mx-2"
        ><i className="fa fa-wechat"></i>
        <p>Feedback</p></Link>
      <Link to="/policy" className="w3-bar-item w3-button mx-3"
        ><i className="fa fa-shield"></i>
        <p>Policy</p></Link>
    </div>
    </>
  )
}

export default PartnerSidebar