// src/features/mealsSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MealsState {
  selectedMeal: string | null;
}

const initialState: MealsState = {
  selectedMeal: null,
};

const mealsSlice = createSlice({
  name: 'meals',
  initialState,
  reducers: {
    selectMeal: (state, action: PayloadAction<string>) => {
      state.selectedMeal = action.payload;
    },
  },
});

export const { selectMeal } = mealsSlice.actions;

export const selectSelectedMeal = (state: { meals: MealsState }) =>
  state.meals.selectedMeal;

export default mealsSlice.reducer;
