import PartnerSidebar from "../PartnerSidebar";
import { useEffect, useState } from "react";

interface feedback {
  booking: string;
  chef: string;
  description: string;
  rating: string;
  status: string;
  createdAt: string;
  user: string;
}

const PartnerFeedback = () => {

  const [feedback, setFeedback] = useState<feedback[]>([])

  const token = localStorage.getItem("accessToken");
  const UserData:any = localStorage.getItem("user");
  const userObj = JSON.parse(UserData);
  const userId = userObj.id;
  const userRole = userObj.role;
  console.log("---------------",userId,userRole)

  useEffect(() => {
    const getPartnerFeedback = async () => {
      try {
        const response = await fetch(`${process.env.React_App_DYO_CHEF_Rating_URL}${userId}?role=${userRole}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer${token}`
            },
          }
        );
        const data = await response.json();
        const feedback = data?.data?.rating;
        console.log(")))))))))))))", feedback)
        setFeedback(feedback)
        console.log(data)
      } catch (error) {
        console.log(error)
      }
    }
    getPartnerFeedback();
  },[token,userId,userRole])

  return (
    <>
      <PartnerSidebar />
      <div>
        <div className="seprator-45">
          <div className="row">
              {
                feedback.map((e: any, index: any) => (
                  <div className="col-md-3">
                  <div className="feedback-card" key={index}>
                    <div className="feedback-card-iteam">
                      <p style={{ margin: "0 auto" }}>{e.description}</p>
                    </div>
                    <hr className="w-100 " style={{ "margin": "5px" }} />
                    <div className="feedback-bottom d-flex justify-content-between">
                      <div className="d-flex">
                        <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/dyo-profile.png" alt="" />
                        <p className="mx-1 mt-1">{e.userName}</p>
                      </div>
                      <div className="text-warning">
                        <i className="fa fa-star"></i><i className="fa fa-star"></i>
                        <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-o"></i>
                      </div>
                    </div>
                  </div>
            </div>
                ))
              }
          </div>
        </div>
      </div>
    </>
  )
}

export default PartnerFeedback;