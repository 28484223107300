import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginType from "./loginInterface";
import "./login.css";
import { useNavigate } from "react-router-dom";

const PartnerLogin: React.FC = () => {
  const [userState, setState] = useState<LoginType>({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setState({ ...userState, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.React_App_DYO_CHEF_Partner_Login_URL}`,
        {
          method: "POST",
          body: JSON.stringify(userState),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const respData = await response.json();
        localStorage.setItem("accessToken", respData?.data?.accessToken);
        localStorage.setItem("user", JSON.stringify(respData?.data?.user));
        console.log("data", respData);
        navigate("/partner/dashboard");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="getway-wrapper">
        <div className="getway-inner">
          <div className="getway-inner-items">
            <h2>
              Welcome to DYO <span> CHEF</span>
            </h2>
            <div className="d-flex justify-content-between align-items-center mt-5 mb-4">
              <strong className="fs-5">Login</strong>
              <p className="mb-0 already-a-member">
                Not a Partner? <Link to="/partner/registration">Sign up</Link>{" "}
              </p>
            </div>
            <form className="d-flex flex-column gap-4" onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="form-label fw-medium"> Email </label>
                <input
                  className="form-control form-input"
                  type="email"
                  placeholder=" Enter your email"
                  name="email"
                  value={userState.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="form-label fw-medium"> Password </label>
                <input
                  className="form-control form-input"
                  type="password"
                  placeholder=" Enter your password"
                  name="password"
                  value={userState.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="form-group already-a-member">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Password Remember?
                  </label>
                </div>
                <p className="mb-0 already-a-member">
                  {" "}
                  <a href="/">Forgot Password?</a>{" "}
                </p>
              </div>

              <button className="btn btn-rounded btn-dyo-primary ">
                {" "}
                Sign In
              </button>
            </form>
          </div>
          <div className="or text-center"></div>
          <div className="text-center">
            <button className="social-link">
              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fgoogle.svg" alt="" />
            </button>
            <button className="social-link">
              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Ffacebook.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerLogin;
