import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const Bookingdetails = () => {

  const location = useLocation();
  const booking = location.state.bookingview;
  console.log(booking);

  return (
    <>
      <div className="booking-view">
        <div className="booking-receipt">
          <div className="d-flex">
            <Link to='/bookingview' className="back-button">
              <i className="fa fa-angle-left ms-3"></i>
            </Link>
              <h2 className="spacing-head">
                Bookings Details
              </h2>
          </div>
          <div className="booking-iteam border shadow-sm p bg-body rounded">
            <div className="row">
              {booking ?
                <>
                  <div className="col-md-6 booking-details">
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/id.svg" alt="user" className="mx-1" />Booking Id</h4>
                    <p>{booking.booking.id}</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/user.svg" alt="user" className="mx-1" />Name</h4>
                    <p>{booking.booking.address.firstName} {booking.booking.address.lastName}</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FRequirement.svg" alt="user" className="mx-1" />Requirement</h4>
                    <p>{booking.booking.requirement}</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FMeals.svg" alt="user" className="mx-1" />Meals</h4>
                    <p>{booking.booking.meals}</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fpeople.svg" alt="user" className="mx-1" />Number Of People</h4>
                    <p>{booking.booking.headCount} people</p>
                  </div>
                  <div className="col-md-6 booking-details">
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fchef.svg" alt="user" className="mx-1" />Chef</h4>
                    <p>{booking.booking.chef.firstName} {booking.booking.chef.lastName}</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FDate.svg" alt="user" className="mx-1" />Booking Date</h4>
                    <p>{booking.booking.startDate}</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FTime.svg" alt="user" className="mx-1" />Duration</h4>
                    <p>2 Hour</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FBill.svg" alt="user" className="mx-1" />Payment</h4>
                    <p>{booking.booking.finalFare}</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Flocation.svg" alt="user" className="mx-1" />Address</h4>
                    <p>{booking.booking.address.address},{booking.booking.address.city},{booking.booking.address.region},{booking.booking.address.zip}</p>
                  </div>
                </> : <>
                  <div className="col-md-6 booking-details">
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/id.svg" alt="user" className="me-2" />ID</h4>
                    <p>:id...</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/user.svg" alt="user" className="me-2" />Name</h4>
                    <p>: Name...</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FRequirement.svg" alt="user" className="me-2" />Requirement</h4>
                    <p>:Requirement...</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FMeals.svg" alt="user" className="me-2" />Meal</h4>
                    <p>:Meal...</p>
                    <h4 className=""><img src="additional" alt="user" className="me-2" />Additional</h4>
                    <p>:addditional...</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fpeople.svg" alt="user" className="me-2" />Number Of People</h4>
                    <p>: people...</p>
                  </div>
                  <div className="col-md-6 booking-details">
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fchef.svg" alt="user" className="me-2" />Chef</h4>
                    <p>:Name</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FDate.svg" alt="user" className="me-2" />Booking Date</h4>
                    <p>: Date...</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FTime.svg" alt="user" className="me-2" />Duration</h4>
                    <p>: Duration...</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FBill.svg" alt="user" className="me-2" />Payment</h4>
                    <p>: payment...</p>
                    <h4 className=""><img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Flocation.svg" alt="user" className="me-2" />Address</h4>
                    <p>: Address...</p>
                  </div>
                </>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bookingdetails;
