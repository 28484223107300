
import advertisementinterface, { Advertisement as AdvertisementType } from "./advertisementinterface";

const Advertisement = (props: advertisementinterface) => {

  const advertisment: AdvertisementType = props.advertisement

  return (
    <>
      <div className="seprator-100">
        <div className="our-story-section">
          <div className="row mx-0">
            <div className="col-sm-5 px-0">
              <img src={advertisment.imageUrl} className="img-fluid" alt="advertisment" />
            </div>
            <div className="col-sm-7 d-flex flex-column justify-content-center align-items-center p-5">
              <div className="our-story-section-title">
                <h2> Our Story </h2>
                <p> THE PERFECT THE LIFE AND FOOD. </p>
              </div>
              <p className="px-5 my-4 py-2 text-center our-story-section-content">    Embark on a journey through our culinary narrative at DYO  Chef, where every dish tells a story of passion,
                dedication, and relentless pursuit of excellence. From our
                humble beginnings to the present, we've woven a tapestry of
                flavors, innovation, and heartfelt hospitality. Our story is
                one of love for food, a commitment to quality, and a
                deep-rooted desire to create memorable dining experiences for
                each and every one of our patrons.</p>
              <button className="btn btn-rounded btn-dyo-primary"> Read More </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Advertisement;
