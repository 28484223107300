import { useState } from "react";
import { Link } from "react-router-dom";

interface UserUpdate {
  firstName: string;
  lastName: string;
  phone: string;
}

const UpdateUserProfile = () => {
  const [userupdate, setUserupdate] = useState<UserUpdate>({
    firstName: "",
    lastName: "",
    phone: "",
  });

  const token = localStorage.getItem("accessToken");
  const user: any = localStorage.getItem("user");
  const userObject = JSON.parse(user);
  const userId = userObject.id;
  console.log("------------", userId);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUserupdate({ ...userupdate, [e.target.name]: e.target.value });
    console.log("user input update", userupdate);
  };

  const handlesave = async (e: any) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.React_App_DYO_CHEF_UserInfo_URL}${userId}`,
        {
          method: "PUT",
          body: JSON.stringify(userupdate),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("user update success");
      }
    } catch (error) {
      console.log("API IS WRONG");
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm  p-5 mb-5 rounded">
            <Link to="/userprofile" className="back-button">
              <i className="fa fa-angle-left me-2"></i>
              Back
            </Link>
            <form className="profile-inner-items">
              <h2 className="text-center">Profile</h2>

              <div className="form-group mb-4">
                <label htmlFor="exampleFormControlInput1">First Name</label>
                <input
                  type="text"
                  className="profile-fields"
                  name="firstName"
                  value={userupdate.firstName}
                  onChange={handleChange}
                  placeholder="Enter First Name"
                />
              </div>

              <div className="form-group mb-4">
                <label htmlFor="exampleFormControlInput1">Last Name</label>
                <input
                  type="text"
                  className="profile-fields"
                  name="lastName"
                  value={userupdate.lastName}
                  onChange={handleChange}
                  placeholder="Enter Last Name"
                />
              </div>
              <div className="form-group mb-4">
                <label htmlFor="exampleFormControlInput1">phone</label>
                <input
                  type="number"
                  className="profile-fields"
                  name="phone"
                  value={userupdate.phone}
                  onChange={handleChange}
                  placeholder="Phone"
                />
              </div>
            </form>
          </div>

          <div className="col-sm profile-image-items text-center">
            <img
              src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/dyo-profile.png"
              className="dyo-profile-update rounded-circle "
              alt="user profile"
            />
            <div className="">
              <button
                className="dyo-button btn-rounded fs-6"
                onClick={handlesave}
              >
                Save
              </button>
              <button className="dyo-button btn-rounded fs-6 mx-5">
                <Link
                  to="/userprofile"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Done
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateUserProfile;
