// import { useNavigate } from "react-router";
// import DocumentType from "./documentInterface"
// import { useState } from "react";


const Documentation = () => {

//   const [user, setUser] = useState<DocumentType>({
//     idProofType: "",
//     idProofNumber: "",
//     maritalStatus: "",
// });

// const navigate = useNavigate();
// const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
//     setUser({ ...user, [e.target.name]: e.target.value });
// }

// const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
//     setUser({ ...user, [e.target.name]: e.target.value });
// }

// const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     try {
//         const response = await fetch(
//             "https://auth.thevinayaksharan.workers.dev/api/auth/register/chef",
//             {
//                 method: "POST",
//                 body: JSON.stringify(user),
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//             }
//         );
//         const respData = await response.json();
//         console.log("data", respData);
//         navigate('/chef/documentation');
//     } catch (error) {
//         //todo show error on snackbar
//         console.log(error);
//     }
// }

  return (
    <>
        <div className="getway-wrapper">
      <div className="getway-inner">
         <div className="getway-inner-items" >
           <h2> Welcome to DYO <span> CHEF</span> </h2> 
           <div className="mt-5 mb-4">
              <strong className="fs-5">Documentation</strong>         
           </div>
         <fieldset className="d-flex flex-column gap-4">
             <div className="form-group">
                <label className="form-label fw-medium "> ID Type </label>
                <select
                    className="form-control form-input"
                    name="region"
                    id="exampleFormControlInput1"
                  >
                    <option value="select">Select ID</option>
                    <option value="Aadhaar Card">Aadhaar Card</option>
                    <option value="PAN Card">PAN Card</option>
                    <option value="Driving License">Driving License</option>
                  </select>
              </div>
             <div className="form-group">
               <label className="form-label fw-medium"> ID Number </label>
               <input className="form-control form-input" type="number" placeholder=" Enter your ID Number" />
            </div>
            <div className="form-group">
              <label className="form-label fw-medium"> Documentation Images </label>
              <input
                    type="file"
                    className="form-control form-input"
                    id="exampleFormControlInput1"
                    placeholder="ID Number"
                  />
            </div>     

            <div className="form-group already-a-member">
             <input type="checkbox" className="form-check-input" id="exampleCheck1" />
             <label className="form-check-label" htmlFor="exampleCheck1" >I agree to the Terms and Conditions</label>
           </div>
           <button className="btn btn-rounded btn-dyo-primary "> Sign up</button>

         </fieldset> 
         </div>    

      </div>
   </div>
    </>
  )
}

export default Documentation