import authenticatorSlice from "./authenticator/authenticator-slice";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
// import counterSlice from "./counter/counter-slice";
import mealsSlice from "./meals/meals-slice";

const rootReducer = combineReducers({
    // counter: counterSlice,
    authorizer: authenticatorSlice,
    meals:mealsSlice,
});
 
const store = configureStore({
    reducer:rootReducer,
})

export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch:()=>typeof store.dispatch=useDispatch;
export const useAppSelector:TypedUseSelectorHook<ReturnType<typeof store.getState>>=useSelector;

export default store;

