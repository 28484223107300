import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface authenticateState{
 isauthenticate:boolean;
 token:string,
 userDetail:any
}
const initialState:authenticateState={
    isauthenticate:false,
    token:"",
    userDetail:{}
}


const authenticateSlice =  createSlice({
    name:'authorizer',
    initialState,
    reducers:{
        login(state, action:PayloadAction<{islogin:boolean, token:string, userDetail?:any}>){
           return {...state, isauthenticate: action.payload.islogin, token: action.payload.token, userDetail:action.payload.userDetail};
            
        }
    }
}) 

export const {login} = authenticateSlice.actions;
export default authenticateSlice.reducer;