import React, { useEffect } from 'react';

declare global {
  interface Window {
    bootstrap: {
      Popover: any;
    };
  }
}

interface PopoverProps {
  selectedMeals: string[];
  placement: 'top' | 'right' | 'bottom' | 'left';
}

const Popover: React.FC<PopoverProps> = ({ selectedMeals, placement }) => {
  useEffect(() => {
    const popoverTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')) as Element[];
    popoverTriggerList.forEach((popoverTriggerEl) => {
      return new window.bootstrap.Popover(popoverTriggerEl);
    });

    return () => {
      popoverTriggerList.forEach((popoverTriggerEl) => {
        const popover = window.bootstrap.Popover.getInstance(popoverTriggerEl);
        if (popover) {
          popover.dispose();
        }
      });
    };
  }, [selectedMeals]);

  // Function to generate popover content based on selected meals
  const getPopoverContent = () => {
    if (selectedMeals.length === 0) {
      return "Select meals to see details.";
    }

    let content = "Selected Meals:\n";

    if (selectedMeals.includes("breakfast") && selectedMeals.includes("lunch") && selectedMeals.includes("snacks") && selectedMeals.includes("dinner")) {
      content = "\nAny 5 items you choose only\n";
      content += "& 3 Main Course Dishes\n";
      content += "- Any 1 Breads\n";
      content += "- Any 1 Rice Dish\n";
      content += "- Raita/Salad\n";
      content += "- Beverage.\n";
    } else if (selectedMeals.includes("lunch") && selectedMeals.includes("snacks") && selectedMeals.includes("dinner")) {
      content = "\nAny 5 items you choose only\n";<br/>
      content += "& 3 Main Course Dishes, Any 1 Breads,Any 1 Rice Dish, Raita/Salad , Beverage..\n";
    } else if (selectedMeals.includes("breakfast") && selectedMeals.includes("lunch") && selectedMeals.includes("snacks")) {
      content = "\nAny 5 items you choose only\n";
      content += "& 3 Main Course Dishes, Any 1 Breads,Any 1 Rice Dish, Raita/Salad , Beverage..\n";
    } else if (selectedMeals.includes("breakfast") && selectedMeals.includes("lunch")) {
      content = "\nAny 5 items you choose only\n";
      content += "& 3 Main Course Dishes, Any 1 Breads,Any 1 Rice Dish, Raita/Salad , Beverage.\n";
    } else if (selectedMeals.includes("breakfast") && selectedMeals.includes("snacks")) {
      content += "\nAny 5 items you choose only\n";
    } else if (selectedMeals.includes("breakfast") && selectedMeals.includes("dinner")) {
      content = "\nAny 5 items you choose only\n";
      content += "& 3 Main Course Dishes, Any 1 Breads,Any 1 Rice Dish, Raita/Salad , Beverage.";
    } else if (selectedMeals.includes("lunch") && selectedMeals.includes("snacks")) {
      content = "\nAny 5 items you choose only\n";
      content += "& 3 Main Course Dishes, Any 1 Breads,Any 1 Rice Dish, Raita/Salad , Beverage.\n";
    } else if (selectedMeals.includes("lunch") && selectedMeals.includes("dinner")) {
      content = "\n3 Main Course Dishes, Any 1 Breads,Any 1 Rice Dish, Raita/Salad , Beverage.\n";
    } else if (selectedMeals.includes("snacks") && selectedMeals.includes("dinner")) {
      content = "\nAny 5 items you choose only\n";
      content += "& 3 Main Course Dishes, Any 1 Breads,Any 1 Rice Dish, Raita/Salad , Beverage.\n";
    } else if (selectedMeals.includes("breakfast")) {
      content = "\nAny 5 items you choose only\n";
    } else if (selectedMeals.includes("lunch")) {
      content = "\n3 Main Course Dishes, Any 1 Breads,Any 1 Rice Dish, Raita/Salad , Beverage.\n";
    } else if (selectedMeals.includes("snacks")) {
      content = "\nAny 5 items you choose only\n";
    } else if (selectedMeals.includes("dinner")) {
      content = "\n3 Main Course Dishes, Any 1 Breads,Any 1 Rice Dish, Raita/Salad , Beverage.\n";
    }

    return content;
  };

  return (
    <button
      type="button"
      className="btn-Popover"
      data-bs-toggle="popover"
      data-bs-placement={placement}
      data-bs-content={getPopoverContent()}
    >
      <i className="fa fa-info-circle text-end mx-2 fs-5"></i>
    </button>
  );
};

export default Popover;
