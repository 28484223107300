import cardinterface from "./cardinterface";

const Card = (props: cardinterface) => {

  const card = props.card;

  return (
    <>

      <div className="seprator-100 discover-section">
        <div className="container">
          <div className="section-title">
            <h2> Discover</h2>
            <p> MOST FAMOUS FEATURES </p>
          </div>
          <div className="discover-inner mt-5">
            <div className="row">
              {card.map((e: any, index) => (
                <div key={index} className="col-sm-4 d-flex flex-column gap-2  align-items-center">
                  <div className="dis-icon">
                    <img src={e.imageUrl} alt="chefservice" className="img-fluid" />
                  </div>
                  <h4>{e.label}</h4>
                  <p className="text-center px-4">{e.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
