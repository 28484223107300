import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginType from "./loginInterface";
import { login } from "../../store/authenticator/authenticator-slice";
import { useAppDispatch, useAppSelector } from "../../store/appStore";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { selectSelectedMeal } from "../../store/meals/meals-slice";
import {toast} from 'react-toastify'
const Login: React.FC = () => {
  const [userState, setState] = useState<LoginType>({
    email: "",
    password: "",
  });
  const dispatch = useAppDispatch();
  const selectedMeal = useAppSelector(selectSelectedMeal);

  const navigate = useNavigate();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setState({ ...userState, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.React_App_DYO_CHEF_Login}`, {
        method: "POST",
        body: JSON.stringify(userState),
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response);
      if (response.status === 200) {
        const respData = await response.json();

        const accessToken = respData?.data?.accessToken;
      
      // Set token expiration time (current time + 7 days) in milliseconds
      const tokenExpiration = new Date().getTime() + 7 * 24 * 60 * 60 * 1000;

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("tokenExpiration", tokenExpiration.toString());
        localStorage.setItem("user", JSON.stringify(respData?.data?.user));
        console.log("data", respData);
        dispatch(
          login({
            islogin: true,
            token: respData?.data?.accessToken,
            userDetail: respData?.data?.user,
          })
        );
        toast("Sign-in sucessful.")
        

        if (selectedMeal) {
          navigate("/booking");
        } else {
          navigate("/");
        }
      } else toast.error("Invalid email or password.");
    } catch  (error) {
       toast("error")
      console.log(error);
    }
  };

  return (
    <>
      <div className="getway-wrapper">
        <div className="getway-inner">
          <div className="getway-inner-items">
            <h2>
              {" "}
              Welcome to DYO <span> CHEF</span>{" "}
            </h2>
            <div className="d-flex justify-content-between align-items-center mt-5 mb-4">
              <strong className="fs-5">Login</strong>
              <p className="mb-0 already-a-member">
                {" "}
                Not a member? <Link to="/user/registration">Sign up</Link>{" "}
              </p>
            </div>
            <form className="d-flex flex-column gap-4" onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="form-label fw-medium"> Email </label>
                <input
                  className="form-control form-input"
                  type="email"
                  placeholder=" Enter your email"
                  name="email"
                  value={userState.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="form-label fw-medium"> Password </label>
                <input
                  className="form-control form-input"
                  type="password"
                  placeholder=" Enter your password"
                  name="password"
                  value={userState.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="form-group already-a-member">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Password Remember?
                  </label>
                </div>
                <p className="mb-0 already-a-member">
                  {" "}
                  <a href="/">Forgot Password?</a>{" "}
                </p>
              </div>
              <button className="btn btn-rounded btn-dyo-primary ">
                {" "}
                Sign In
              </button>
            </form>
          </div>
          <div className="or text-center"></div>
          <div className="text-center">
            <button className="social-link">
              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fgoogle.svg" alt="" />
            </button>
            <button className="social-link">
              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Ffacebook.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
