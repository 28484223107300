import { useState } from 'react';
import { FaStar } from 'react-icons/fa';

const UserRatings = () => {
    const [rating, setRating] = useState<number | null>(null);
    const [hover, setHover] = useState<number | null>(null);
    const [note,setNote] = useState<string>("")

    const handleReview = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        console.log("revice")
        setNote(e.target.value)
        console.log("(((((((((((((((((((",note)
    }   

    const handleSubmit = () => {
        console.log("Rating:", rating);
        console.log("Review:", note);
    }

    return (
        <>
            {[...Array(5)].map((_, index) => {
                const currentRating = index + 1;
                return (
                    <label key={index}>
                        <input
                            type="radio"
                            name="rating"
                            value={currentRating}
                            style={{ display: 'none' }}
                            onClick={() => setRating(currentRating)}
                        />
                        <FaStar
                            className="star"
                            size={20}
                            color={currentRating <= (hover ?? rating ?? 0) ? "#ffc107" : "#e4e5e9"}
                            onMouseEnter={() => setHover(currentRating)}
                            onMouseLeave={() => setHover(null)}
                        />
                    </label>
                );
            })}
            <p>Your rating is {rating}</p>
            <textarea className='user-rating' value={note} onChange={handleReview} />
            <button onClick={handleSubmit} >submit</button>
            
        </>
    );
};

export default UserRatings;
