import { Fragment } from 'react/jsx-runtime';
import './App.css';
import Nav from './pages/Navigation';
import AppRoutes from './Routes';
// import Coupon from './components/Coupon';

const App = () => {
  return (
    <Fragment>
      {/* <Coupon/> */}
      <Nav  />
      <AppRoutes />
    </Fragment>
  );
}

export default App;
