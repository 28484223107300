// import React from 'react'
import { useEffect, useState } from "react";
import "../../userprofile/profile.css";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader/index"

interface userdetail {
  firstName: string;
  lastName: string;
  phone: string;
  address: string;
  email: string;
}

const Partnerprofile = () => {
  const [user, setUser] = useState<userdetail>();
  const [address, setAddress] = useState([]);

  const token = localStorage.getItem("accessToken");
  const userData: any = localStorage.getItem("user");
  const userObject = JSON.parse(userData);
  const userId = userObject.id;
  console.log("77777777777777777777777", userId)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.React_App_DYO_CHEF_Save_Address_URL}${userId}/addresses`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const addresses = data.data.addresses;
        setAddress(addresses);
        // setAddressid(addresses.toString());
        console.log("Addresses fetched: ", addresses);
      } catch (error) {
        console.error("Fetch error: ", error);
      }
    };

    fetchData();
  }, [token, userId]);

  const handleAddressSelect = (e: any) => {
    console.log("Save Address", e.target.value);
    const selectedValue = JSON.parse(e.target.value)
    console.log(selectedValue.id)
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.React_App_DYO_CHEF_Partner_Profile_URL}${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        console.log("________+_+_+_+_", data.data.chef);
        const userdetails = data.data.chef;
        setUser(userdetails);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchData();
  }, [token, userId]);


  if (!user) {
    return <div className="text-center loader-position"><Loader /></div>;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm  p-5 mb-5 rounded">
            <Link to="/" className="back-button">
              <i className="fa fa-angle-left me-2"></i>
              Back
            </Link>
            <form className="profile-inner-items">
              <h2 className="text-center">Profile</h2>
              <div className="form-group mb-4">
                <label htmlFor="exampleFormControlInput1">Name</label>
                <label className="profile-fields">
                  {user.firstName} {user.lastName}
                </label>
              </div>

              <div className="form-group mb-4">
                <label htmlFor="exampleFormControlInput1">Email</label>
                <label className="profile-fields">{user.email}</label>
              </div>

              <div className="form-group mb-4">
                <label htmlFor="exampleFormControlInput1">Phone Number</label>
                <label className="profile-fields">+91 {user.phone}</label>
              </div>

              <h3 className="text-center">Addresses</h3>

              <div className="form-group mb-4">
                {address.map((address: any, index: any) => (
                  <div className="d-flex address-layout" key={index}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="address"
                      value={JSON.stringify(address)}
                      id={`flexCheckDefault-${index}`}
                      onClick={handleAddressSelect}
                    />
                    <div className="mx-2">
                      <h6>{address.firstName} {address.lastName}</h6>
                      <p>{address.address},{address.city},{address.region},{address.zip}</p>
                      <p>{address.phone}</p>
                    </div>
                  </div>
                ))}
              </div>
            </form>
          </div>

          <div className="col-sm profile-image-items text-center">
            <div className="text-end">
              <button className="profile-edit">
                <Link to="/user/profile/update"><i className="fa fa-edit"></i></Link>
              </button>
            </div>
            <img
              src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/dyo-profile.png"
              className="dyo-profile rounded-circle ms-5 "
              alt="user profile"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Partnerprofile;
