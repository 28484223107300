// import { useNavigate } from "react-router";
// import workingExperinceType from "./workingExperinceInterface";
// import { useState } from "react";


const WorkingExperince = () => {

    // const [user, setUser] = useState<workingExperinceType>({
    //     designation: "master chef",
    //     currentWorkLocation: "",
    //     totalExperience: "5",
    // });

    // const navigate = useNavigate();
    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    //     setUser({ ...user, [e.target.name]: e.target.value });
    // }

    // const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    //     setUser({ ...user, [e.target.name]: e.target.value });
    // }

    // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     try {
    //         const response = await fetch(
    //             "https://auth.thevinayaksharan.workers.dev/api/auth/register/chef",
    //             {
    //                 method: "POST",
    //                 body: JSON.stringify(user),
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //             }
    //         );
    //         const respData = await response.json();
    //         console.log("data", respData);
    //         navigate('/chef/documentation');
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }


    return (
        <>

            <div className="getway-wrapper">
                <div className="getway-inner">
                    <div className="getway-inner-items">
                        <h2>Welcome to DYO <span> CHEF</span></h2>
                        <div className="mt-5 mb-4">
                            <strong className="fs-5">Working Experience</strong>
                        </div>
                        <fieldset className="d-flex flex-column gap-4">

                            <div className="form-group">
                                <label className="form-label fw-medium"> Experience </label>
                                <select className="form-control form-input" name="region" id="exampleFormControlInput1" >
                                    <option value="select" >Enter Experience</option>
                                    <option value="1"  > 1 Year</option>
                                    <option value="2" > 2 Year</option>
                                    <option value="3" >3 Year</option>
                                    <option value="1"  > 4 Year</option>
                                    <option value="2" > 5 Year</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="form-label fw-medium"> Current location </label>
                                <input
                                    className="form-control form-input"
                                    type="text"
                                    placeholder=" Enter your Current Location"
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label fw-medium"> Salary </label>
                                <input
                                    className="form-control form-input"
                                    type="text"
                                    placeholder=" Enter your Salary"
                                />
                            </div>

                            <div className="form-group already-a-member">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="exampleCheck1"
                                />
                                <label className="form-check-label" htmlFor="exampleCheck1">I agree to the Terms and Conditions</label>
                            </div>
                            <button className="btn btn-rounded btn-dyo-primary">Sign up</button>
                        </fieldset>
                    </div>

                </div>
            </div>
        </>
    )
}

export default WorkingExperince;